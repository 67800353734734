import { AppInsightService } from '@arkadium/modules/dist/lib/_shared/services/AppInsightService';
import { AnalyticsPageTypes } from '@/app.model';

export const initAppInsight = async (isForServer = false) => {
    await AppInsightService.init(
        !isForServer
            ? process.env.APP_INSIGHTS_INSTRUMENTATION_KEY_FRONT ?? '3f5dc740-6749-4354-9777-4a3ede6633ca'
            : process.env.APP_INSIGHTS_INSTRUMENTATION_KEY ?? 'b8609952-68a6-4ccb-a2b7-1336394530f2',
        window.location.href,
        {},
        window.innerWidth + 'x' + window.innerHeight
    );
};

export type BaseParams = {
    pageType: AnalyticsPageTypes;
    fpVisitorId: string;
    fpIncognito: boolean;
    isMobile: boolean;
    loggedIn: boolean;
    arenaVersion: string;
    buildVersion: string;
    domain: string;
    screenResolution: string;
    targetConcent: boolean | null;
    gdprConsent: boolean | null;
};

export type BaseCustomDimensions = {
    arenaID: string;
    arenaLayoutID: string;
    modularPageID: string;
    utmSource: string;
    utmMedium: string;
    utmCampaign: string;
};

export type AiData = {
    baseParams: BaseParams;
    baseCustomDimensions: BaseCustomDimensions;
};

export const baseParams: any = (
    pageType?: AnalyticsPageTypes,
    fpVisitorId?: string,
    fpIncognito?: boolean,
    isMobile?: boolean,
    loggedIn?: boolean,
    gdprConsent?: boolean | null
): BaseParams => {
    return {
        pageType,
        deviceType: isMobile ? 'phone' : 'pc',
        fpVisitorId: fpVisitorId,
        fpIncognito: fpIncognito,
        loggedIn: loggedIn ?? false,
        arenaVersion: 'arena-evolution',
        isMobile: isMobile ?? false,
        buildVersion: process.env.buildid ?? 'dev',
        domain: typeof window !== 'undefined' ? window?.location?.host : '',
        screenResolution: typeof window !== 'undefined' ? window.innerWidth + 'x' + window.innerHeight : '0x0',
        gdprConsent,
    } as unknown as BaseParams;
};
