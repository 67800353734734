const LocalStorageProvider = (() => {
    const data: Record<string, string> = {};
    const prefix: string = 'evo:';
    let lsUnavailable = false;
    let blockedByGdpr: boolean | null = null;

    // Check if localStorage is available
    try {
        window.localStorage.getItem('');
    } catch (e) {
        lsUnavailable = true;
    }

    const setItem = (key: string, value: any, noprefix = false) => {
        if (blockedByGdpr) {
            return;
        }
        // Prefix the key if needed
        if (!noprefix) {
            key = `${prefix}${key}`;
        }
        if (lsUnavailable) {
            data[key] = String(value);
        } else {
            window.localStorage.setItem(key, String(value));
        }
    };

    const getItem = (key: string, noprefix = false): string | null => {
        if (!noprefix) {
            key = `${prefix}${key}`;
        }
        if (lsUnavailable) {
            return data[key];
        }
        const value = window.localStorage.getItem(key);
        // Check if the retrieved value is null or undefined
        if (!value || value === 'undefined' || value === 'null') {
            return '';
        }
        return value;
    };
    const removeItem = (key: string) => {
        if (lsUnavailable) {
            delete data[key];
        } else {
            window.localStorage.removeItem(key);
        }
    };

    const setBlockedByGdpr = (consent: boolean) => {
        blockedByGdpr = !consent;
    };

    return {
        getItem,
        setItem,
        removeItem,
        setBlockedByGdpr,
    };
})();

export const LocalStorageService = LocalStorageProvider;

export enum LSKeys {
    gamePlayCount = 'ark_game_play_count',
    lastGamePlay = 'ark_last_gameplay',
    targetingConsent = 'ark_modules:targetingConsent',
    gdprCookieConsent = 'ark_modules:gdprCookieConsent',
}
