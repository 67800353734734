import { ConfigGamePage } from '@/root/app/[locale]/games/[slug]/model';
import { LSKeys, LocalStorageService } from '@/shared/utils/local-storage';
import { createEvent, createStore, sample } from 'effector';

export enum GameState {
    GAME = 'GAME',
    GAME_END = 'GAME_END',
    GAME_SCORE_SAVED = 'GAME_SCORE_SAVED',
    PENDING = 'PENDING',
    PREROLL = 'PREROLL',
    COVER = 'COVER',
    ADBLOCK = 'ADBLOCK',
    REWARD = 'REWARD',
    INTERSTITIAL = 'INTERSTITIAL',
    PREROLL_PLAYING = 'PREROLL_PLAYING',
}
export enum ARK_MESSAGES {
    END_PREROLL = 'arkadium:endPreroll',
    START_PREROLL = 'arkadium:startPreroll',
    START_INTERSTITIAL = 'arkadium:startInterstitial',
    START_REWARD = 'arkadium:startReward',
}
export const gameStateUpdated = createEvent<GameState>();
export const $gameStateStore = createStore<GameState>(GameState.PENDING, { name: 'gameState' });
const INIT_SHOULD_SHOW_PREROLL_STATE = true;
export const $shouldShowPreroll = createStore<boolean>(INIT_SHOULD_SHOW_PREROLL_STATE);
export const checkPrerollShowRate = createEvent<{
    isPrerollEnabled: boolean;
    prerollShowRate: ConfigGamePage['prerollShowRate'];
}>();
const GAME_PLAY_CLEAR_TIME = 12; // hours
sample({
    clock: gameStateUpdated,
    source: $gameStateStore,
    fn: (currentGameState, passedGameState) => {
        if (currentGameState === GameState.ADBLOCK) {
            return currentGameState;
        }
        if (
            (currentGameState === GameState.PREROLL || currentGameState === GameState.PREROLL_PLAYING) &&
            passedGameState === GameState.GAME
        ) {
            const gamePlayCount = Number(LocalStorageService.getItem(LSKeys.gamePlayCount));
            LocalStorageService.setItem(LSKeys.gamePlayCount, String(gamePlayCount + 1));
        }
        return passedGameState;
    },
    target: $gameStateStore,
});

sample({
    clock: checkPrerollShowRate,
    fn: ({ isPrerollEnabled, prerollShowRate }) => {
        if (!isPrerollEnabled) {
            return false;
        }
        if (prerollShowRate === 1 || prerollShowRate === null) {
            return INIT_SHOULD_SHOW_PREROLL_STATE;
        }

        const lastGamePlay = LocalStorageService.getItem(LSKeys.lastGamePlay);
        const needClearGamePlays =
            lastGamePlay && (Date.now() - Date.parse(lastGamePlay)) / 1000 / 60 / 60 >= GAME_PLAY_CLEAR_TIME;

        if (needClearGamePlays) {
            LocalStorageService.removeItem(LSKeys.gamePlayCount);
        }

        LocalStorageService.setItem(LSKeys.lastGamePlay, new Date().toString());

        const gamePlayCount = Number(LocalStorageService.getItem(LSKeys.gamePlayCount));
        return gamePlayCount % prerollShowRate === 0;
    },
    target: $shouldShowPreroll,
});
