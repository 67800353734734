export const consoleDev = (...args: any) => {
    //print server consoles anywhere
    if (typeof window === 'undefined') {
        console.debug(...args);
        return;
    }
    const query = new URLSearchParams(window.location.search);
    if (process.env.BUILD_ENV === 'development' || query.get('__debug')) {
        console.debug(...args);
    }
};
